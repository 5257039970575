import * as icons from 'src/uikit/icons/ContentIcons/TechIcons';

type IconType = {
  [key: string]: React.ComponentType<{ className?: string }>;
};

export const getIcon = (name: string): string | JSX.Element => {
  if (icons && (icons as IconType).hasOwnProperty(name)) {
    const IconComponent = (icons as IconType)[name];
    return <IconComponent />;
  } else {
    return name;
  }
};

export const getIconForSnack = (name: string, alt: string) => {
  if (icons && (icons as IconType).hasOwnProperty(name)) {
    const newSnackIcon = (icons as IconType)[`${name}SVG`];
    if (newSnackIcon) {
      return newSnackIcon;
    }

    const IconComponent = (icons as IconType)[name];
    return <IconComponent />;
  } else {
    return { src: name, alt };
  }
};
