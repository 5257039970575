import {
  SectionBenefits,
  SectionBenefitsProps,
} from '@sbercloud/uikit-product-site-section';
import { useRouter } from 'next/navigation';
import { MouseEvent, useMemo } from 'react';
import { consoleUrlReg } from 'src/global';
import { useAnalytics } from 'src/hooks/useAnalytics';
import { getIconForSnack } from 'src/utils/getIcon';

import { useFormatConsoleUrl } from '../../hooks/useFormatConsoleUrl';
import { useLayoutType } from '../../hooks/useLayoutType';
import { Variant } from '../Button/constants';
import s from './Benefits.module.scss';
import { AlignContent, Grid, Hover, TargetLinkType, Type } from './constants';
import { BenefitsProps } from './types';

export function Benefits({
  id,
  title,
  description,
  cards,
  cardWithShadow = false,
  cardWithNumeric = false,
  outline,
  grid = Grid.TwoColumns,
  registerButton,
  buttons,
  onButtonsClick,
}: BenefitsProps) {
  const router = useRouter();
  const { clickAnalytics } = useAnalytics();
  const layoutType = useLayoutType();

  const consoleUrl = useFormatConsoleUrl(consoleUrlReg, 'body', 'В консоль');

  const columnsConfig = useMemo(() => {
    switch (grid) {
      case Grid.FourColumns: {
        return {
          mobile: { amount: 1, minWidth: 150 },
          tablet: { amount: 2, minWidth: 150 },
          desktop: { amount: 4, minWidth: 250 },
        };
      }
      case Grid.ThreeColumns: {
        return {
          tablet: { amount: 1, minWidth: 150 },
          desktop: { amount: 3, minWidth: 250 },
        };
      }
      case Grid.TwoColumns:
      default: {
        return {
          mobile: { amount: 1, minWidth: 310 },
          tablet: { amount: 2, minWidth: 310 },
          desktop: { amount: 2, minWidth: 300 },
        };
      }
    }
  }, [grid]);

  const getContent = () => {
    const cardsHasSlug = cards.some((card) => card.slug);
    let cardsType: SectionBenefitsProps['type'] =
      cardsHasSlug || cardWithShadow ? 'info' : 'basic';

    if (cardWithNumeric) {
      cardsType = 'numeric';
    }

    const content = cards.map((card) => ({
      title: card.title,
      description: card.text,
      href: card.slug as string,
      icon:
        typeof card.icon === 'string'
          ? getIconForSnack(card.icon, card.title)
          : card.icon,
      target: card.target,
      className: s.snackBenefitsCard,
      onClick: (e: MouseEvent<HTMLAnchorElement>) => {
        if (card.slug) {
          if (card.target !== '_blank' && card.slug.startsWith('/')) {
            e.preventDefault();
            router.push(card.slug);
          }

          clickAnalytics({
            action: card.slug,
            clickZone: 'body',
            clickElement: 'cardclick',
            clickContent: card.title,
            uniqueId: `benefits-link-${title}`,
            transitionType: 'inside-link',
          });
        }
      },
    })) satisfies SectionBenefitsProps['content'];

    if (cardsType === 'basic') {
      return {
        type: 'basic',
        content,
      } satisfies Pick<SectionBenefitsProps, 'type' | 'content'>;
    }

    if (cardsType === 'numeric') {
      return {
        type: 'numeric',
        content,
      } satisfies Pick<SectionBenefitsProps, 'type' | 'content'>;
    }

    return {
      type: 'info',
      outline,
      content,
    } satisfies Pick<
      SectionBenefitsProps & { type: 'info' },
      'type' | 'content' | 'outline'
    >;
  };

  const getButtons = (): SectionBenefitsProps['buttons'] => {
    const buttonsArr: BenefitsProps['buttons'] = registerButton
      ? [
          {
            title: 'Зарегистрироваться',
            onClick: (e) => {
              e.preventDefault();
              router.push(consoleUrl);
            },
          },
        ]
      : buttons?.filter(
          (button, index) => button.link || Boolean(onButtonsClick?.[index]),
        );

    return buttonsArr?.map((button, index) => ({
      label: button.title,
      href: button.link,
      target: '_blank',
      onClick: (e) => {
        clickAnalytics({
          action: button.link ?? 'click',
          clickZone: 'body',
          clickElement: 'button',
          clickContent: button.title,
          uniqueId: `benefits-${button.link ? 'link' : 'button'}-${
            button.title
          }`,
          transitionType: 'inside-link',
        });

        button.onClick?.(e);
        onButtonsClick?.[index]?.();
      },
    }));
  };

  return (
    <SectionBenefits
      id={id}
      title={title}
      description={description}
      columnsConfig={columnsConfig}
      layoutType={layoutType}
      buttons={getButtons()}
      {...getContent()}
    />
  );
}

Benefits.grid = Grid;
Benefits.buttonVariants = Variant;
Benefits.alignContent = AlignContent;
Benefits.type = Type;
Benefits.targetLinkType = TargetLinkType;
Benefits.hover = Hover;
