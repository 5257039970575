export enum Grid {
  OneColumn = 'oneColumn',
  TwoColumns = 'twoColumns',
  ThreeColumns = 'threeColumns',
  FourColumns = 'fourColumns',
}

export enum AlignContent {
  Top = 'top',
  Middle = 'middle',
}

export enum Type {
  PrimaryCard = 'primaryCard',
  PreviewCard = 'previewCard',
}

export enum TargetLinkType {
  Self = '_self',
  Blank = '_blank',
}

export enum Hover {
  Full = 'full',
  OnlyTitle = 'onlyTitle',
}
